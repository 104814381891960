<template src="./template.html"></template>

<script>
import UserDropdown from "@/components/user-dropdown/user-dropdown.vue";

export default {
  name: "choose-product",
  components: {UserDropdown},
  data(){
    return {
      apps: [],
      isFinishCheck: false
    }
  },
  async created(){
    this.isFinishCheck = false
    await this.handleApp()
  },
  async mounted() {

  },
  methods:{
    getIcon(app) {
      return app.icon
    },
    onAppClick(app){
      console.log('On app click', app)
      window.location.replace(app.url)
    },
    myProfile(){
      this.$router.push('/change-profile')
    },
    async logout(){
      this.$emit('showLoading', true)
      await this.$auth.signOut().then(() => {
        this.$emit('showLoading', false)
        this.$store.dispatch('clearAllUserData')
        this.$router.push('/')
      })
    },
    async handleApp(){

      if(this.$store.state.user === null){
        await this.$auth.currentAuthenticatedUser().then(async (user) => {
          this.$store.commit('saveUserData', user)
        }).catch((error) => {
          console.log(error)
        });
      }

      if(this.$store.getters.getMe == null){
        await this.$store.dispatch('getMe', this.$store.state.user.username)
      }
      this.$emit('showLoading', false)
      this.apps =  this.$store.getters.getMe && this.$store.getters.getMe.apps ? this.$store.getters.getMe.apps : null
      if(this.apps != null){
        if(this.apps.length === 1){
         if(this.apps[0].url === undefined || this.apps[0].url === 'https:not-important'){
           console.log('Only not important app')
         }else{
           window.location.replace(this.apps[0].url)
         }
        }
      }

      setTimeout(()=>{
        this.isFinishCheck = true
      }, 500)
    },
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>