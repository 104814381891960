<template src="./template.html"></template>
<script>
export default {
  name: 'Login-Page',
  data() {
    return {
      isLoading: false,
      username: null,
      password: null,
      codeForm: false,
      code: null,
    }
  },
  async created() {
    await this.checkAuth()
  },
  methods: {
    async checkAuth() {
      console.log('Check auth: ', this.$store.state.user)
      await this.$auth.currentAuthenticatedUser().then(async (user) => {
        this.$store.commit('saveUserData', user)
        console.log('User', this.$store.state.user)
        await this.$router.push('choose-product')
      }).catch((error) => {
        console.log(error)
      });
    },
    toForgot() {
      this.$router.push('/forgot')
    },
    toSignUp() {
      this.$router.push('/register')
    },
    login: async function () {
      this.$emit('showLoading', true)
      this.$auth.signIn(this.username, this.password)
          .then(async (user) => {
            console.log(user);
            this.$store.commit('saveUserData', user)
            if (user.challengeName === 'SMS_MFA') {
              this.codeForm = true
            } else {
              this.$store.commit('saveUserData', user)
              if (this.$store.getters.getMe == null) {
                await this.$store.dispatch('getMe', this.$store.state.user.username)
              }
              await this.$router.push('choose-product')
            }
            this.$emit('showLoading', false)
          })
          .catch((err) => {
            this.loginError(err)
          });
    },
    loginError(error) {
      this.$emit('showLoading', false)
      this.$buefy.notification.open({
        duration: 5000,
        message: error.message,
        position: 'is-bottom-right',
        type: 'is-danger',
        hasIcon: false
      })
    },
    async confirmCode() {
      console.log(this.code)
      this.$emit('showLoading', true)
      let user = this.$store.state.user
      this.$auth.confirmSignIn(user, this.code).then(async (user) => {
        console.log('User is', user)
        this.$store.commit('saveUserData', user)
        if (this.$store.getters.getMe == null) {
          await this.$store.dispatch('getMe', this.$store.state.user.username)
        }
        this.$emit('showLoading', false)
        await this.$router.push('choose-product')
      })
          .catch((err) => {
            this.loginError(err)
          });
    },
    toLogin() {
      this.codeForm = false
    },
  }
}
</script>
<style scoped lang="scss" src="./style.scss"></style>
