<template src="./template.html"></template>

<script>
export default {
  name: "reset-password",
  data() {
    return {
      email: null,
      code: null,
      password: null,
      confirmPass: null
    }
  },
  mounted() {
    this.email = this.$route.query.email
  },
  methods: {
    toLogin(){
      this.$router.push('/')
    },
    async setNewPassword() {
      if (this.$refs.pass.isValid && this.$refs.confirmPass.isValid) {
        if (this.code && this.password && this.confirmPass) {
          if (this.password !== this.confirmPass) {
            this.showError('Passwords not match')
            return
          }

          this.$emit('showLoading', true)

          await this.$auth.forgotPasswordSubmit(this.email, this.code, this.password)
              .then((data) => {
                this.$buefy.notification.open({
                  message: 'Password successfully changed',
                  type: 'is-success'
                })

                setTimeout(() => {
                  this.toLogin()
                }, 1000)
              })
              .catch((error) => {
                this.$buefy.notification.open({
                  duration: 5000,
                  message: error.message,
                  position: 'is-bottom-right',
                  type: 'is-danger',
                  hasIcon: false
                })
              });

          this.$emit('showLoading', false)
        }
      }
    },
    showError(message) {
      this.$emit('showLoading', false)
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger'
      })
    }
  }
}
</script>

<style scoped lang="scss" src="./style.scss"></style>