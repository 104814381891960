import {Role} from "@/utils/utils";

export default {
    token: (state) => {
        return 'Bearer ' + state.user.signInUserSession.idToken
    },
    getUserFullName: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['name']}`
    },
    getUserRole: (state) => {
        return state.user.signInUserSession.idToken.payload['custom:role']
    },
    getUserPhone: (state) => {
        console.log('Session:', state.user)
        return `${state.user.signInUserSession.idToken.payload['phone_number']}`
    },
    getUserId: (state) => {
        return state.user.signInUserSession.idToken.payload['sub']
    },
    isAuth: (state) => {
        return !!state.user
    },
    getMe: (state) => {
        return state.me
    }
}
