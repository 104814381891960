<template src="./template.html"></template>

<script>

export default {
  name: "activate-mfa",
  data(){
    return {
      backTo: null
    }
  },
  async mounted() {
    this.$emit('showLoading', true)
    await this.$auth.currentAuthenticatedUser().then(async (user) => {
      this.$store.commit('saveUserData', user)
      console.log('User is: ', user)
    }).catch((error) => {
      console.log(error)
    });

    if (this.$store.getters.getMe == null) {
      await this.$store.dispatch('getMe', this.$store.state.user.username)
    }
    this.apps = this.$store.getters.getMe && this.$store.getters.getMe.apps ? this.$store.getters.getMe.apps : null

    let appName = this.$route.query.name
    console.log('App Name: ', appName)
    let appIndex = this.apps.findIndex(app => app.name === appName)
    if (appIndex !== -1) {
      this.backTo = this.apps[appIndex]
    }

    if(this.$store.state.user.preferredMFA === 'NOMFA') {
      let response = await this.$auth.setPreferredMFA(this.$store.state.user, 'SMS')
      console.log('Response activate MFA: ', response)
      if(response === 'SUCCESS'){
        this.$buefy.notification.open({
          message: 'MFA successfully activated',
          type: 'is-success'
        })
        this.$emit('showLoading', false)
        await this.logout()
      }
    }else {
      await this.logout()
    }

    this.$emit('showLoading', false)


  },
  methods:{
    async logout(){
      this.$emit('showLoading', true)
      await this.$auth.signOut().then(() => {
        this.$emit('showLoading', false)
        this.$store.dispatch('clearAllUserData')
        this.$router.push('/')
      })
    },
  }
}
</script>

<style scoped>

</style>