<template>
  <div>
    <b-loading
        v-model="isLoading"
        :can-cancel="true"
        :is-full-page="true"
    ></b-loading>
    <div style="display: flex; margin-top: 8px; width: 100%">
      <div class="media mr-4" v-if="user != null">
        <avatar :user="user"></avatar>
        <div class="media-content" style="margin-left: 8px; margin-top: 12px;">
          <h6 class="is-6 has-text-weight-semibold" style="margin-bottom: 0; line-height: 0.5;">{{ user.fullName }}</h6>
          <small class="is-size-7 has-text-grey-light" style="white-space: nowrap">{{ user.job }}</small>
        </div>
      </div>
      <b-navbar-dropdown class="ml-auto" :collapsible="true" :boxed="true" :right="true">
        <b-navbar-item @click="onChangePassword">
          Update password
        </b-navbar-item>
        <b-navbar-item @click="onChangeProfile">
          Change profile
        </b-navbar-item>
        <b-navbar-item v-if="isMfaDisabled" @click="onActivateMFA">
          Activate MFA
        </b-navbar-item>
        <b-navbar-item @click="logout">
          Logout
        </b-navbar-item>
      </b-navbar-dropdown>
    </div>
  </div>
</template>

<script>
import avatar from "@/components/avatar/avatar.vue";
import Vue from "vue";

export default {
  name: "user-dropdown",
  components: {
    avatar
  },
  data() {
    return {
      isEditProfileActive: false,
      isLoading: false,
      isMfaDisabled: false
    }
  },
  mounted() {
    this.isMfaDisabled = this.$store.state.user.preferredMFA === 'NOMFA'
  },
  computed: {
    user() {
      return {
        avatar: null,
        fullName: this.$store.getters.getUserFullName,
        job: this.$store.getters.getUserRole,
      }
    }
  },
  methods: {
    async logout() {
      this.isLoading = true
      await this.$auth.signOut().then(() => {
        this.$router.push('/')
        this.$store.dispatch('clearAllUserData')
      })
      this.isLoading = false
    },
    onChangePassword() {
      this.$router.replace('/change-password')
    },
    onChangeProfile() {
      this.$router.replace('/change-profile')
    },
    onActivateMFA() {
      if (this.$store.getters.getUserPhone && this.$store.getters.getUserPhone.length > 0) {
        this.$router.replace('/activate-mfa')
      } else {
        this.$buefy.toast.open(
            {
              duration: 5000,
              message: 'To activate Multi Factor Authorisation first set phone number for your profile. Go to Change Profile and set phone number.'
            }
        )
      }
    }
  }

}
</script>

<style scoped>

</style>