<template src="./template.html"></template>
<script>


import {
  parsePhoneNumber, isPossiblePhoneNumber,
  isValidPhoneNumber
} from 'libphonenumber-js'

export default {
  name: 'Register-Page',
  data() {
    return {
      username: null,
      newPassword: null,
      confirmNewPassword: null,
      name: null,
      organisation: null,
      location: null,
      phone: null,
      code: null,
      codeForm: false,
      //mfa: false
    }
  },
  mounted() {
    this.$refs.phoneNum.setValidity(false, '')
  },
  methods: {
    onPhoneChanged(value) {
      console.log('On phone changed: ', value)
      if (value.length > 6) {
        const phoneNumber = parsePhoneNumber(value)
        console.log('Phone', phoneNumber)
        if (phoneNumber.country) {
          let isPossible = isPossiblePhoneNumber(value, phoneNumber.country) === true
          let isValid = isValidPhoneNumber(value, phoneNumber.country) === true
          let message = isValid ? '' : 'Invalid phone number'
          console.log('Type', phoneNumber.getType())
          this.$refs.phoneNum.setValidity(isValid, message)
        } else {
          this.$refs.phoneNum.setValidity(false, 'Invalid phone number')
        }
        this.phone = phoneNumber.formatInternational()
      } else {
        this.$refs.phoneNum.setValidity(false, 'Invalid phone number')
      }
    },
    register: async function () {
      if(!this.isValid()){
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Form is not valid',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
        return
      }
      if (this.newPassword !== this.confirmNewPassword) {
        this.$buefy.notification.open({
          duration: 5000,
          message: 'Passwords not match',
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })

        return
      }

      let phoneNum = this.phone ? this.phone.replace(/\s+/g, '') : ''
      this.$emit('showLoading', true)
            this.$auth.signUp({
              username: this.username,
              password: this.newPassword,
              attributes: {
                name: this.name,
                phone_number: phoneNum,
                email: this.username,
                'custom:organisation': this.organisation
              }
            }).then((user) => {
              this.$buefy.notification.open({
                message: 'Verification code is sent to email: ' + this.username,
                type: 'is-success'
              })
              this.codeForm = true;
              this.$emit('showLoading', false)
            }).catch((error) => {
              this.$buefy.notification.open({
                duration: 5000,
                message: error.message,
                position: 'is-bottom-right',
                type: 'is-danger',
                hasIcon: false
              })
              this.$emit('showLoading', false)
            });
    },
    sendCodeAgain: async function () {
      try {
        await this.$auth.resendSignUp(this.username);
        console.log('code resent successfully');
      } catch (err) {
        console.log('error resending code: ', err);
      }
    },
    confirmSignUp() {
      this.$emit('showLoading', true)
      this.$auth.confirmSignUp(this.username, this.code).then((data) => {
        this.$buefy.notification.open({
          message: 'Verification code is correct, you can Login now',
          type: 'is-success'
        })
        this.$emit('showLoading', false)
        this.$router.push('/')
      }).catch((error) => {
        this.$emit('showLoading', false)
        this.$buefy.notification.open({
          duration: 5000,
          message: error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          hasIcon: false
        })
      });
    },
    toLogin() {
      this.$router.push('/')
    },
    isValid(){
      if(this.$refs.name && this.$refs.pass && this.$refs.confirmPass && this.$refs.username && this.$refs.phoneNum){
        if(this.$refs.name.value && this.$refs.name.value.length > 0 && this.$refs.pass.isValid && this.$refs.confirmPass.isValid && this.$refs.username.isValid && this.$refs.phoneNum.isValid && this.$refs.phoneNum.value && this.$refs.phoneNum.value.length > 6){
          return true
        }

        return false
      }
    }
  }
}
</script>
<style scoped lang="scss" src="./style.scss"></style>
