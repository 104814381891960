import Vue from "vue";

export default {
    getMe: async ({commit}, userId) => {
        let config = {
            method: 'get',
            url: Vue.prototype.$env.VUE_APP_API_URL + '/v1-uac/uac-me'
        };
        await Vue.prototype.$http(config).then(function (response) {
            console.log('Get me response: ', response.data)
            if(response.data){
                console.log('Setting users')
                commit('setMe', response.data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    },
    clearAllUserData: async ({commit}) => {
        commit('removeUserData')
    },
}

