export default {
  /**
   * Save user data
   * @param state (this state)
   * @param item
   */
  saveUserData(state, item) {
    state.user = item;
  },
  /**
   * Remove user data
   * @param state (this state)
   * @param item
   */
  removeUserData(state, item) {
    state.user = null
    state.me = null
  },
  setMe(state, user){
    state.me = user
  }
};
