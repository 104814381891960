import Vue from 'vue'
import VueRouter from 'vue-router'
import {Auth} from 'aws-amplify';
import loginPage from '@/views/login/login.vue'
import register from '@/views/register/register.vue'
import forgot from "@/views/forgot/forgot.vue";
import resetPassword from "@/views/reset-password/reset-password.vue";
import forbiddenPage from "@/views/forbidden/forbidden-page.vue";
import notFound from "@/views/not-found/not-found.vue";
import chooseProduct from "@/views/choose-product/choose-product.vue";
import changePassword from "@/views/change-password/change-password.vue";
import changeProfile from "@/views/change-profile/change-profile.vue";
import activateMfa from "@/views/activate-mfa/activate-mfa.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        meta:{
            title: 'Login',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: loginPage
    },
    {
        path: '/register',
        name: 'register',
        meta:{
            title: 'Register',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: register
    },
    {
        path: '/forgot',
        name: 'forgot',
        meta:{
            title: 'Forgot Password',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: forgot
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta:{
            title: 'Reset password',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: resetPassword
    },
    {
        path: '/change-password',
        name: 'change-password',
        meta:{
            title: 'Change password',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: changePassword
    },
    {
        path: '/change-profile',
        name: 'change-profile',
        meta:{
            title: 'Change profile',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: true
        },
        component: changeProfile
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        meta:{
            title: 'Forbidden',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: forbiddenPage
    },
    {
        path: '/choose-product',
        name: 'choose-product',
        meta:{
            title: 'Choose product',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: chooseProduct
    },
    {
        path: '/activate-mfa',
        name: 'activate-mfa',
        meta:{
            title: 'Activate MFA',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: activateMfa
    },
    {
        path: '*',
        name: 'not-found',
        meta:{
            title: 'Not found',
            hasMenu: false,
            isUnrestricted: true,
            hasInternalTransition: false
        },
        component: notFound
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
/**
 * Auth check if user is logged in
 */
router.beforeEach(async (to, from, next) => {
    //Disable check just for these routes
    console.log('Router next', to)

    if (to.meta.isUnrestricted) {
        //NOT Authorized
        next()
    } else {
        //Authorized
        await Auth.currentAuthenticatedUser().then((user) => {
            console.log('Route user: ', user)
            let role = user.signInUserSession.idToken.payload['custom:role']
            if(to.meta.roles && to.meta.roles.length > 0){
                if(to.meta.roles.includes(role)){
                    console.log('Calling next')
                    next()
                }else {
                    console.log('Navigation guard: Can\'t access this page')
                    router.replace({ name: 'forbidden' })
                }
            }else{
                console.log('Navigation guard: Provide user roles')
            }
        }).catch((error) => {
            //NOT Authorized, redirect to log in
            if(to.name !== 'login'){
                router.push({ name: 'login' })
            }
            console.log('Navigation guard: ', error)
        });
    }
})
export default router
